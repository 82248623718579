// i18next-extract-mark-ns-start products-wave

import { LINKS, WAVE_APPLICATIONS_NAV, WAVE_CAPABILITIES_NAV, WAVE_PUBLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWavePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const wavert = getImage(data.wavert.childImageSharp.gatsbyImageData);
	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							WAVE and its faster than real-time component WAVE-RT are at the forefront of 1D CFD
							technology for the analysis of Internal Combustion Engines (ICE).
							Our approach, combining precision and user friendliness, empowers engineers globally
							to forge intricate digital engine twins that epitomize design concepts.
							This capability proves invaluable for tasks such as analysis, optimisation, calibration, diagnostics, and maintenance of IC engines.
							Prioritising the precision of simulation and including targeted capabilities aimed at sustainable and non-carbon fuel modelling,
							WAVE finds applications across diverse industrial sectors such as automotive, aerospace, rail, motorsport, marine, and power generation.
							The paramount commitment of WAVE to customer satisfaction propels the transition towards virtual product development,
							enabling engineers to embrace cutting-edge innovations and accelerated R&D, all while curbing development costs.
						</p>
					</Trans>
					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							WAVE is the technology leader in 1D engine plant model analysis supporting a wide range of
							applications. From concept studies to detailing engine performance and cooling system inputs, from
							optimisation of engine components to acoustic analysis and noise level optimisation; from fuel
							composition sensitivity to fault analysis and maintenance - WAVE can provide accurate answers to
							complex questions facing engineering analysis teams.
						</p>
					</Trans>
					{wavert && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wavert}
								alt="WAVE-RT"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_3">
						<p>
							WAVE-RT is the industry’s leading faster than real-time engine plant modelling solver. Created from
							WAVE models with a single click export, it has been developed for fast comprehensive virtual
							calibration and validation. WAVE-RT is used to model advanced control strategies that need to be
							designed, tested and validated for integration into new engine control units.
							Furthermore, current generation WAVE-RT has fidelity approaching full 1D CFD modelling and can provide a drop-in
							engine plant replacement in many pre-development tasks, such as transmission calibration.
							Calibrated WAVE-RT engine plant models also enable system level simulations for applications such
							as digital monitoring, fault analysis, drivability and OBD system improvement in Model-in-the-Loop,
							Software-in-the-Loop and Hardware-in-the-Loop environments.
						</p>

						<h2>Key features</h2>

						<p>
							The WAVE product suite plays a pivotal role throughout the entire engine life cycle, improving the way we design,
							develop, and maintain engines. From inception to operation and maintenance, WAVE seamlessly integrates into your
							workflow, delivering unmatched precision and efficiency. From the initial design stages, encompassing performance
							and component design, control systems development through virtual calibration, and comprehensive performance
							verification for IC engines, powertrains, and finally - delivering the accurate real-time heart for complete vehicle
							simulation environments.
						</p>
					</Trans>
					{image && (
						<p>
							<GatsbyImage image={image} alt="WAVE workflow" />
						</p>
					)}
					<Trans i18nKey="content_4">
						<p>
							Going beyond engine design and production, WAVE and its faster than real-time WAVE-RT engine model helps our
							customers enhance engine operation and maintenance workflows enabling fault detection and online performance
							monitoring based on digital twin technology.
						</p>
						<p>
							Our customers across diverse sectors benefit from WAVE's unparalleled usability and accuracy, spanning the spectrum
							of Internal Combustion Engines. From compact utility engines to marine and power generation giants WAVE
							equips our customers to tackle pressing challenges in today's business landscape.
						</p>
						<p>
							From cost optimisation, performance enhancement, and emissions reduction to embracing hybridisation,
							synthetic fuels, and achieving overall carbon neutrality.
						</p>

						<h2>Be at the centre of decision making</h2>

						<p>
							At Realis, we understand that each customer is unique and we are offering a close partnership helping our customers build
							tailored solutions and processes reflecting their workflows and maximizing efficiency of their CAE process.
							We put our customers in the centre of decision making by offering :
						</p>
						<ul>
							<li>Direct interaction with product and development teams through customer driven product development</li>
							<li>Bespoke model development and rapid enhancement development based on customer feedback</li>
							<li>Early access to requested features through customized software versions</li>
							<li>Dedicated and tailored support, customized interactive training</li>
							<li>Process and model building consulting services</li>
							<li>Tailored licensing solutions</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Publications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_PUBLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductWavePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
		...imageBreaker
	}
	wavert: file(relativePath: { eq: "content/products/wave/rs_wavert.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE_Value.jpg" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "WAVE" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;

